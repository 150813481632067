<script setup lang="ts">
import type { Candidacy } from '@grid/types/candidacy'
import type { Campaign } from '@grid/types/campaign'
import { StartCampaigns } from '@grid/mutations/StartCampaigns.gql'
import { useAPI } from '@grid/hooks/useAPI'
import { useModal } from '@grid/hooks/useModal'
import { usePipelineStore } from '@grid/store'

interface Failure {
  reason: string
  candidacy: Candidacy
}

const props = defineProps<{
  candidacies: Candidacy[]
}>()

const emit = defineEmits(['completed'])

const store = usePipelineStore()
const { campaigns } = storeToRefs(store)
const { updateCandidacy } = store
const { mutate, loading } = useAPI()

const startedCampaign = ref<Campaign | undefined>()
const addedCandidacies = ref<Candidacy[]>([])
const failures = ref<Failure[]>([])
const candidacyIds = computed(() => props.candidacies.map(c => c.id))

const dialog = useTemplateRef<HTMLDialogElement>('dialog')
const { openModal, closeModal, modalTransition } = useModal(dialog)

async function startCampaigns (campaign: Campaign): Promise<void> {
  mutate({
    mutation: StartCampaigns,
    variables: {
      ids: candidacyIds.value,
      campaignId: campaign.id
    }
  }).then(response => {
    startedCampaign.value = campaign
    addedCandidacies.value = response.data.startCampaigns.candidacies
    failures.value = response.data.startCampaigns.failures

    openModal()

    addedCandidacies.value.forEach(({ id, ...candidacy }) => {
      updateCandidacy(id, candidacy)
    })
  })
}

function reset (): void {
  startedCampaign.value = undefined
  addedCandidacies.value = []
  failures.value = []
}

function onClose (): void {
  modalTransition().then(() => reset())
  emit('completed')
}
</script>

<template>
  <div class="flex gap-3 items-center py-2 px-4 w-full">
    <FontAwesome icon="paper-plane" />
    <span class="flex-1 text-left">
      Add to campaign:
    </span>
  </div>

  <button
    v-for="campaign in campaigns"
    :key="campaign.id"
    type="submit"
    class="w-full font-medium text-left text-gray-500"
    @click="() => startCampaigns(campaign)"
  >
    <div :aria-busy="loading" class="flex gap-3 items-center py-2 px-4 w-full hover:bg-primary-100">
      <FontAwesome icon="blank" />
      <span class="flex-1 text-left">
        {{ campaign.name }}
      </span>
    </div>
  </button>

  <dialog
    ref="dialog"
    class="overflow-hidden text-left rounded-lg w-[600px] has-[[data-fullscreen]]:min-w-[90vw] max-h-[90vh]"
    @close="onClose"
  >
    <div class="flex overflow-y-hidden flex-col max-h-[90vh]">
      <ModalHeader icon="circle-exclamation" text="Add candidates to campaign" :dialog="dialog" />

      <ModalContent>
        <div class="flex flex-col gap-3">
          <p v-if="addedCandidacies.length > 0 && startedCampaign !== undefined">
            <strong class="font-semibold">{{ addedCandidacies.length }} candidate{{ addedCandidacies.length > 1 ? 's' : '' }}</strong>
            {{ addedCandidacies.length > 1 ? 'were' : 'was' }} added to <strong class="font-semibold">{{ startedCampaign.name }}</strong>.
          </p>

          <div v-if="failures.length > 0">
            <p class="mb-2">
              <strong class="font-semibold">{{ failures.length }} candidate{{ failures.length > 1 ? 's' : '' }}</strong>
              {{ failures.length > 1 ? 'were' : 'was' }} not added:
            </p>

            <table class="table w-full font-normal table-fixed">
              <tr v-for="failure in failures" :key="failure.candidacy.id" class="border border-gray-100">
                <td class="py-2 px-2">
                  {{ failure.candidacy.firstName }} {{ failure.candidacy.lastName }}
                </td>
                <td class="py-2 px-1">
                  {{ failure.reason }}
                </td>
              </tr>
            </table>
          </div>
        </div>
      </ModalContent>

      <ModalFooter>
        <button type="button" class="btn-primary" @click="closeModal">
          Got it!
        </button>
      </ModalFooter>
    </div>
  </dialog>
</template>
