<script setup lang="ts">
import type { GridState } from 'ag-grid-community'
import type { Candidacy } from './types/candidacy'
import { usePipelineStore } from './store'
import { useBucket } from '@grid/hooks/useBucket'
import { useMetaTag } from '@grid/hooks/useMetaTag'

defineProps<{
  requisitionId: number
  state: GridState
}>()

const csr: boolean = useMetaTag('csr')
const defaultBucket = csr ? 'identified' : 'review'

const bucketDefs = [
  ...(csr ? [{ bucket: 'identified', text: 'Identified' }] : []),
  { bucket: 'review', text: 'In Review' },
  { bucket: 'approved', text: 'Approved' },
  { bucket: 'rejected', text: 'Rejected' },
  ...(csr ? [{ bucket: 'published', text: 'All' }] : [])
]

const { candidacyDB } = storeToRefs(usePipelineStore())
const { activeBucket, bucketed: candidacies } = useBucket<Candidacy>(candidacyDB, defaultBucket)
</script>

<template>
  <CandidateGrid
    :state="state"
    :candidacies="candidacies"
    :bucket="activeBucket"
    namespace="index"
  >
    <template #buckets>
      <BucketTab
        v-for="bucketDef in bucketDefs"
        :key="bucketDef.bucket"
        :text="bucketDef.text"
        :name="bucketDef.bucket"
        :active="activeBucket === bucketDef.bucket"
        @click="activeBucket = bucketDef.bucket"
      />
    </template>

    <template #actions>
      <Hootie v-if="csr" :requisition-id="requisitionId" />
      <CandidacyImporter :requisition-id="requisitionId" />
    </template>
  </CandidateGrid>
</template>
