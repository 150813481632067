import { toValue } from 'vue'
import type { Ref, ComputedRef, MaybeRefOrGetter } from 'vue'

interface Primaryable {
  primary: boolean
}

interface Returns<T> {
  primary: ComputedRef<T>
  others: ComputedRef<T[]>
}

export function usePrimary<T extends Primaryable> (objects: MaybeRefOrGetter<T[]>): Returns<T> {
  const objectsRef = computed(() => toValue(objects))

  const primaryIndex = computed(() => {
    const index = objectsRef.value.findIndex(it => it.primary) ?? 0
    return index >= 0 ? index : 0
  })

  const primary = computed(() => objectsRef.value[primaryIndex.value])
  const others = computed(() => objectsRef.value.filter((_, index) => index !== primaryIndex.value))

  return {
    primary,
    others
  }
}
