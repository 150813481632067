<script setup lang="ts">
import type { GridApi, Column } from 'ag-grid-community'
import { watch } from 'vue'

const props = defineProps<{
  column: Column
}>()

const { column } = toRefs(props)
const gridApi: GridApi = column.value.gos.api

const name = gridApi.getDisplayNameForColumn(column.value, null)
const isVisible = ref<boolean>(column.value.isVisible())

function toggleVisibility (): void {
  isVisible.value = !isVisible.value
}

watch(isVisible, newVisible => {
  gridApi.setColumnsVisible([column.value], newVisible)
})
</script>

<template>
  <button v-if="name.length > 0" type="button" class="flex gap-2 items-center py-2 w-full" :class="{ 'opacity-50': !isVisible }" @click="toggleVisibility">
    <i v-if="isVisible" class="cursor-pointer fa-regular fa-eye text-primary-700" />
    <i v-else class="text-gray-900 cursor-pointer fa-regular fa-eye-slash" />
    <span>{{ name }}</span>
  </button>
</template>
