<script setup lang="ts">
import { usePipelineStore } from './store'
import { useMetaTag } from '@grid/hooks/useMetaTag'
import type { Candidacy } from '@grid/types/candidacy'
import type { Bucket } from '@grid/types/bucket'

const props = defineProps<{
  candidacies: Candidacy[]
  bucket: Bucket
}>()

const emit = defineEmits(['completed'])

const { candidacies } = toRefs(props)
const csr: boolean = useMetaTag('csr')

const store = usePipelineStore()
const { publishCandidacies } = store

const count = computed(() => candidacies.value?.length ?? 0)

const canExportToClient = computed(() => {
  return csr && props.bucket === 'identified'
})

async function publish (): Promise<void> {
  void publishCandidacies(candidacies.value)
  emit('completed')
}
</script>

<template>
  <Popover :disabled="count === 0">
    <template #trigger>
      <div class="flex gap-2 items-center px-3 ml-2 h-9 btn min-w-9">
        <i class="fa-solid fa-bolt fa-lg text-primary-700" />
        <span class="py-1 px-2 text-xs font-semibold rounded-full bg-primary-100 text-primary-700">
          {{ count }}
        </span>
      </div>
    </template>

    <template #popover>
      <div class="text-sm font-medium text-gray-700 min-w-[340px]">
        <CandidateActions :candidacies="candidacies" />

        <button v-if="canExportToClient" type="button" class="flex items-center w-full" @click="publish">
          <div class="flex gap-3 items-center py-2 px-4 w-full hover:bg-primary-100">
            <i class="fa-regular fa-fw fa-arrow-right" />
            <span class="flex-1 text-left">Export to client</span>
          </div>
        </button>
      </div>
    </template>
  </Popover>
</template>
