<script setup lang="ts">

const props = defineProps<{
  requisitionId: number
}>()

const importHref = `/requisitions/${props.requisitionId}/candidacy_imports/new`
</script>

<template>
  <a :href="importHref" data-action="modal#open" class="flex gap-2 items-center px-3 ml-2 h-9 btn min-w-9">
    <i class="fa-regular fa-fw fa-cloud-arrow-up fa-lg text-primary-700" />
    <span>Import</span>
  </a>
</template>
