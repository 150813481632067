<script setup lang="ts">
import { useDebounceFn } from '@vueuse/core'
import { AgGridVue } from 'ag-grid-vue3'
import { AllCommunityModule, ModuleRegistry } from 'ag-grid-community'
import type { GridState, StateUpdatedEvent, CellEditingStoppedEvent, ValueFormatterParams } from 'ag-grid-community'
import type { Candidacy } from './types/candidacy'
import type { Bucket } from '@grid/types/bucket'
import RankingCell from './cells/RankingCell.vue'
import ActionsCell from './cells/ActionsCell.vue'
import EmailCell from './cells/EmailCell.vue'
import EmailEditCell from './cells/EmailEditCell.vue'
import PhoneCell from './cells/PhoneCell.vue'
import PhoneEditCell from './cells/PhoneEditCell.vue'
import ProfileCell from './cells/ProfileCell.vue'
import ProfileEditCell from './cells/ProfileEditCell.vue'
import LocationEditCell from './cells/LocationEditCell.vue'
import dateCellOptions from './cells/dateCellOptions'
import { usePipelineStore } from '@grid/store'
import useGridStore from '@grid/stores/grid'

// Necessary for AG Grid
ModuleRegistry.registerModules([AllCommunityModule])

const props = defineProps<{
  state: GridState
  candidacies: Candidacy[]
  bucket: Bucket
  namespace: string
}>()

const candidateStore = usePipelineStore()
const { loading } = storeToRefs(candidateStore)
const { editable, saveCandidacy } = candidateStore

const gridStore = useGridStore(props.namespace)
const { columns, selections } = storeToRefs(gridStore)
const { gridReady, selectionChanged, modelUpdated, saveState } = gridStore

const selectionColumnDef = {
  pinned: true
}

const colDefs = ref([
  {
    field: 'id',
    headerName: '',
    resizable: false,
    sortable: false,
    filter: false,
    cellRenderer: 'ActionsCell',
    width: '50',
    lockPosition: true,
    pinned: 'left'
  },
  { field: 'firstName', editable },
  { field: 'lastName', editable },
  { field: 'aiRanking', headerName: 'AI Ranking' },
  { field: 'currentPosition' },
  { field: 'currentCompany' },
  {
    field: 'location',
    editable,
    cellEditor: 'LocationEditCell',
    cellEditorPopup: true,
    valueFormatter: (params: ValueFormatterParams): string => params.value?.name
  },
  { field: 'emails', cellRenderer: 'EmailCell', editable, cellEditor: 'EmailEditCell', cellEditorPopup: true },
  { field: 'phones', cellRenderer: 'PhoneCell', editable, cellEditor: 'PhoneEditCell', cellEditorPopup: true },
  { field: 'profiles', cellRenderer: 'ProfileCell', editable, cellEditor: 'ProfileEditCell', cellEditorPopup: true },
  {
    field: 'ongoingCampaign',
    valueFormatter: (params: ValueFormatterParams): string => params.value?.name
  },
  { field: 'dateAdded', ...dateCellOptions },
  { field: 'dateApproved', ...dateCellOptions },
  { field: 'dateRejected', ...dateCellOptions }
])

const gridOptions = {
  maintainColumnOrder: true
}

async function editingStopped (event: CellEditingStoppedEvent): Promise<void> {
  saveCandidacy(event.data)
    .catch(() => console.error('Failed to save candidacy', event.data))
}

const stateUpdated = useDebounceFn((event: StateUpdatedEvent) => {
  saveState(event.state)
}, 3000)

defineExpose({
  RankingCell,
  ActionsCell,
  EmailCell,
  EmailEditCell,
  PhoneCell,
  PhoneEditCell,
  ProfileCell,
  ProfileEditCell,
  LocationEditCell
})
</script>

<template>
  <div class="flex flex-col grow">
    <div class="flex gap-3 items-center py-2 px-4">
      <slot name="buckets" />

      <div class="flex gap-1 items-center ml-auto">
        <slot name="actions" />
        <ColumnPicker :columns="columns" />
        <LightningBolt :candidacies="selections" :bucket="bucket" />
      </div>
    </div>

    <div class="grow grid-wrapper">
      <AgGridVue
        style="height: 100%;"
        :loading="loading"
        :initial-state="state"
        :row-data="candidacies"
        :column-defs="colDefs"
        :grid-options="gridOptions"
        :default-col-def="{ filter: true, lockPinned: true }"
        :selection-column-def="selectionColumnDef"
        :row-selection="{ mode: 'multiRow', enableClickSelection: false, enableSelectionWithoutKeys: true }"
        :animate-rows="false"
        @state-updated="stateUpdated"
        @cell-editing-stopped="editingStopped"
        @selection-changed="selectionChanged"
        @model-updated="modelUpdated"
        @grid-ready="gridReady"
      />
    </div>
  </div>
</template>
