<script setup lang="ts">
import type { Candidacy } from '@grid/types/candidacy'
import { usePipelineStore } from '@grid/store'

const props = defineProps<{
  candidacy: Candidacy
}>()

const candidacy = toRef(props, 'candidacy')
const store = usePipelineStore()
const { campaigns } = storeToRefs(store)
</script>

<template>
  <Popover>
    <template #trigger>
      <div class="flex justify-center items-center rounded-full border bg-primary-50 size-8 border-primary-200 text-primary-700 group-has-[:popover-open]:bg-primary-700 group-has-[:popover-open]:text-white group-has-[:popover-open]:border-primary-700">
        <FontAwesome icon="thumbs-up" />
      </div>
    </template>

    <template #popover>
      <div class="text-sm font-medium text-gray-700 min-w-[340px]">
        <section>
          <ActionMoveBucket bucket="approved" text="Approve, don't add to campaign" :candidacies="[candidacy]">
            <template #icon>
              <div class="flex justify-center items-center rounded-full border bg-primary-50 size-6 border-primary-200 text-primary-700">
                <FontAwesome icon="thumbs-up" />
              </div>
            </template>
          </ActionMoveBucket>

          <hr class="my-1 w-full h-px bg-gray-200 only:hidden">
        </section>

        <section v-if="campaigns.length > 0">
          <ActionStartCampaigns :candidacies="[candidacy]" />
        </section>
      </div>
    </template>
  </Popover>
</template>
