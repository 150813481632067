<script setup lang="ts">
import type { Candidacy } from '@grid/types/candidacy'

const props = defineProps<{
  candidacy: Candidacy
}>()

const candidacy = toRef(props, 'candidacy')
</script>

<template>
  <CandidacyRejectionModal :candidacies="[candidacy]">
    <template #trigger>
      <button
        type="button"
        class="flex justify-center items-center rounded-full border bg-error-50 size-8 border-error-200 text-error-700"
      >
        <FontAwesome icon="thumbs-down" />
      </button>
    </template>
  </CandidacyRejectionModal>
</template>
