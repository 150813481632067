<script setup lang="ts">
defineProps<{
  label: string
}>()

const model = defineModel()
const id = crypto.randomUUID()
</script>

<template>
  <div>
    <input :id="id" v-model="model" type="checkbox" class="hidden peer">
    <label
      :for="id"
      class="block py-2 px-8 text-sm text-center rounded-full border cursor-pointer text-primary-700 border-primary-300 bg-primary-50 peer-checked:border-primary-500 peer-checked:bg-primary-500 peer-checked:text-white peer-disabled:cursor-default"
    >
      {{ label }}
    </label>
  </div>
</template>
