<script setup lang="ts">
import type { ICellRendererParams } from 'ag-grid-community'
import type { Phone } from '../types/phone'
import { useDeletion } from '../hooks/useDeletion'

const props = defineProps<{
  params: ICellRendererParams
}>()

const phones = ref<Phone[]>(props.params.value)
const { isDeleted, toggleDeletion } = useDeletion<Phone>()

function setAsPrimary (primaryPhone: Phone): void {
  phones.value = phones.value.map(phone => {
    return { ...phone, primary: phone.number === primaryPhone.number }
  })
}

function add (): void {
  phones.value.push({ number: '', primary: false })
}

function getValue (): Phone[] {
  return phones.value.filter(phone => !isDeleted(phone) && phone.number.length > 0)
}

function save (): void {
  props.params.api.stopEditing()
}

if (phones.value.length === 0) {
  add()
}

defineExpose({ getValue })
</script>

<template>
  <div class="p-2 bg-white border border-gray-200 shadow-sm">
    <fieldset class="flex flex-col gap-1 text-sm">
      <div v-for="(phone, index) in phones" :key="index" class="flex gap-1 items-center">
        <input v-model="phone.number" type="tel" class="py-1 px-2 w-full text-sm border border-gray-300 min-w-[200px]">

        <div>
          <i v-if="phone.primary" class="fa-solid fa-star text-primary-700" />

          <button v-else type="button" @click="setAsPrimary(phone)">
            <i class="fa-regular fa-star text-primary-700" />
          </button>
        </div>

        <button type="button" @click="toggleDeletion(phone)">
          <i
            class="fa-trash text-primary-700"
            :class="{ 'fa-solid text-red-600': isDeleted(phone), 'fa-regular': !isDeleted(phone) }"
          />
        </button>
      </div>

      <div class="flex gap-1 items-center mt-1">
        <button type="button" class="py-2 w-full text-center btn" @click="add">
          <FontAwesome icon="plus" /> Add
        </button>

        <button type="button" class="w-full text-center btn-primary" @click="save">
          <FontAwesome icon="save" /> Save
        </button>
      </div>
    </fieldset>
  </div>
</template>
