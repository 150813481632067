<script setup lang="ts">
const { position, company } = defineProps<{
  position?: string
  company?: string
}>()

const positionStr = computed(() => position ?? '')
const companyStr = computed(() => company ?? '')
</script>

<template>
  <span v-if="positionStr.length === 0 && companyStr.length === 0">
    Unknown employment
  </span>

  <span v-if="positionStr.length > 0">{{ positionStr }}</span>
  <span v-if="positionStr.length > 0 && companyStr.length > 0">@</span>
  <span v-if="companyStr.length > 0" class="underline">{{ companyStr }}</span>
</template>
