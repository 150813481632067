<script setup lang="ts">
const props = defineProps<{
  firstName: string
  lastName: string
}>()

const initials = computed(() => {
  const first = props.firstName.charAt(0)?.toUpperCase() ?? ''
  const last = props.lastName.charAt(0)?.toUpperCase() ?? ''
  return `${first}${last}`
})
</script>

<template>
  <div class="flex justify-center items-center min-w-full h-full font-bold text-white bg-gray-600 select-none">
    <span class="text-xl">{{ initials }}</span>
  </div>
</template>
