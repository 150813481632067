<script setup lang="ts">
import type { ICellRendererParams } from 'ag-grid-community'

const props = defineProps<{
  params: ICellRendererParams
}>()

const ranking = props.params.value ?? 0
</script>

<template>
  <div class="flex gap-1 items-center h-full">
    <i v-for="index in ranking" :key="index" class="fa-regular fa-star text-primary" />
  </div>"
</template>
