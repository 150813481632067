import { Controller } from '@hotwired/stimulus'

export default class extends Controller<HTMLElement> {
  static targets = ['count', 'countable']

  declare readonly countTarget: HTMLElement
  declare readonly countableTargets: HTMLElement[]

  connect (): void {
    this.update()
  }

  countableTargetConnected (): void {
    this.update()
  }

  update (): void {
    this.countTarget.dataset.count = this.countableTargets.length.toString()
  }
}
