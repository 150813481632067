interface Returns {
  openModal: () => void
  closeModal: () => void
  modalTransition: () => Promise<void>
}

export function useModal (dialog: Ref<HTMLDialogElement>): Returns {
  function openModal (): void {
    dialog.value?.showModal()
  }

  function closeModal (): void {
    dialog.value?.close()
  }

  async function modalTransition (): Promise<void> {
    const promise = new Promise<void>(resolve => {
      setTimeout(() => resolve(), 300)
    })

    await promise
  }

  return {
    openModal,
    closeModal,
    modalTransition
  }
}
