export function useDeletion<T> (): { isDeleted: (object: T) => boolean, toggleDeletion: (object: any) => void, others: T[] } {
  const deletions = ref<T[]>([])

  function isDeleted (object: T): boolean {
    return deletions.value.includes(object)
  }

  function toggleDeletion (object: any): void {
    if (isDeleted(object)) {
      const index = deletions.value.indexOf(object)
      deletions.value.splice(index, 1)
    } else {
      deletions.value.push(object)
    }
  }

  return {
    isDeleted,
    toggleDeletion
  }
}
