<script setup lang="ts">
import type { ICellRendererParams } from 'ag-grid-community'
import { usePrimary } from '../hooks/usePrimary'
import type { Phone } from '../types/phone'
import Popover from '../ui/Popover.vue'

const props = defineProps<{
  params: ICellRendererParams
}>()

const { primary, others } = usePrimary<Phone>(props.params.value ?? [])

const popoverId = computed(() => crypto.randomUUID())
</script>

<template>
  <div v-if="primary" class="flex gap-1 items-center text-left">
    <span>{{ primary.number }}</span>

    <Popover v-if="others.length > 0" :id="popoverId">
      <template #trigger>
        <span class="py-1 px-2 text-xs rounded-full bg-primary-100 text-primary-700">
          +{{ others.length }}
        </span>
      </template>

      <template #popover>
        <div class="flex flex-col gap-1 p-3">
          <span v-for="phone in others" :key="phone.number">
            {{ phone.number }}
          </span>
        </div>
      </template>
    </Popover>
  </div>
</template>
