import type { ValueFormatterParams } from 'ag-grid-community'

function valueFormatter (params: ValueFormatterParams): string {
  if (params.value === null) return ''
  const date = new Date(params.value)
  return date.toLocaleDateString()
}

function comparator (date1: string | null, date2: string | null, _nodeA: string, _nodeB: string, isInverted: boolean): Date {
  const isDate1Empty = date1 === null || date1 === ''
  const isDate2Empty = date2 === null || date2 === ''
  if (isDate1Empty && isDate2Empty) return 0
  if (isDate1Empty) return isInverted ? -1 : 1
  if (isDate2Empty) return isInverted ? 1 : -1
  return new Date(date1) - new Date(date2)
}

function isSameDay (date1: Date, date2: Date): boolean {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  )
}

const filterParams = {
  comparator: (filterDate: Date, cellValue: string) => {
    if (cellValue === '') return 0
    const cellDate = new Date(cellValue)
    if (isSameDay(cellDate, filterDate)) return 0
    if (cellDate < filterDate) return -1
    if (cellDate > filterDate) return 1
    return 0
  },
  browserDatePicker: true
}

export default {
  cellDataType: 'date',
  filter: 'agDateColumnFilter',
  valueFormatter,
  comparator,
  filterParams
}
