<script setup lang="ts">
import type { ICellRendererParams } from 'ag-grid-community'
import type { Location } from '../types/location'
import { Combobox, ComboboxInput, ComboboxOptions, ComboboxOption } from '@headlessui/vue'
import { useGooglePlaces } from '../hooks/useGooglePlaces'
import { watch, nextTick } from 'vue'

const props = defineProps<{
  params: ICellRendererParams
}>()

const location = ref<Location>(props.params.value)
const query = ref('')
const chosenPlace = ref()
const input = ref()
const { places } = useGooglePlaces(query)

watch(chosenPlace, place => {
  if (place?.formattedAddress !== null && place?.formattedAddress !== undefined) {
    location.value = { name: stripPostalCode(place.formattedAddress), googlePlaceId: place.id }
    props.params.api.stopEditing()
  }
})

onMounted(async () => {
  if (input.value !== undefined) {
    await nextTick()
    input.value.el.focus()
  }
})

function stripPostalCode (address: string | null | undefined): string {
  const pattern = /,?\s(\d{5}(-\d{4})?|[A-Z]\d[A-Z] ?\d[A-Z]\d|〒\d{3}-\d{4})/
  if (address === undefined || address === null) return ''
  return address.replace(pattern, '')
}

function getValue (): Location | null {
  return location.value ?? null
}

defineExpose({ getValue })
</script>

<template>
  <div class="p-2 bg-white border border-gray-200 shadow-lg">
    <Combobox v-model="chosenPlace" as="div">
      <ComboboxInput ref="input" class="py-1 px-2 w-full text-sm border border-gray-300 min-w-[200px]" @change="query = $event.target.value" />

      <ComboboxOptions class="overflow-auto absolute py-1 mt-1 w-full max-h-60 text-base bg-white rounded-md ring-1 shadow-lg sm:text-sm focus:outline-none ring-black/5">
        <div
          v-if="places.length === 0 && query !== ''"
          class="relative py-2 px-4 text-gray-700 cursor-default select-none"
        >
          Nothing found.
        </div>

        <ComboboxOption
          v-for="place in places"
          :key="place.id"
          v-slot="{ active }"
          as="template"
          :value="place"
        >
          <li
            class="relative py-2 pr-4 pl-10 cursor-pointer select-none"
            :class="{ 'bg-primary-100': active }"
          >
            <span class="block truncate">
              {{ stripPostalCode(place.formattedAddress) }}
            </span>
          </li>
        </ComboboxOption>
      </ComboboxOptions>
    </Combobox>
  </div>
</template>
