<script setup lang="ts">
import type { Candidacy } from '@grid/types/candidacy'
import type { Profile } from '@grid/types/profile'
import type { Email } from '@grid/types/email'
import { usePrimary } from '@grid/hooks/usePrimary'

const { candidacy } = defineProps<{
  candidacy: Candidacy
}>()

const name = computed(() => `${candidacy.firstName} ${candidacy.lastName}`)
const currentPosition = computed(() => candidacy.currentPosition)
const currentCompany = computed(() => candidacy.currentCompany)
const locationName = computed(() => candidacy.location?.name)

const {
  primary: primaryProfile,
  others: otherProfiles
} = usePrimary<Profile>(() => candidacy.profiles)

const {
  primary: primaryEmail,
  others: otherEmails
} = usePrimary<Email>(() => candidacy.emails)

const canApproveOrReject = computed(() => candidacy.buckets.includes('review'))
</script>

<template>
  <section class="p-3 bg-gray-50 rounded border border-gray-200">
    <div class="flex gap-3">
      <div class="overflow-hidden rounded-md size-18">
        <CandidacyAvatar :first-name="candidacy.firstName" :last-name="candidacy.lastName" />
      </div>

      <div class="grow">
        <div class="grid items-center auto-rows-[1fr]">
          <div class="flex items-center w-full">
            <h1 class="text-lg font-semibold leading-4">
              {{ name }}
            </h1>

            <div class="flex gap-3 items-center self-start ml-auto">
              <ApproveButton v-if="canApproveOrReject" :candidacy="candidacy" />

              <RejectButton v-if="canApproveOrReject" :candidacy="candidacy" />

              <Popover>
                <template #trigger>
                  <div class="flex justify-center items-center rounded-full btn size-8">
                    <FontAwesome icon="ellipsis-vertical" size="lg" />
                  </div>
                </template>

                <template #popover>
                  <div class="text-sm font-medium text-gray-700 min-w-[340px]">
                    <CandidateActions :candidacies="[candidacy]" />
                  </div>
                </template>
              </Popover>
            </div>
          </div>
        </div>

        <p class="pb-2 leading-4 text-gray-900">
          <EmploymentStr :position="currentPosition" :company="currentCompany" />
        </p>

        <div v-if="locationName !== undefined" class="flex gap-1 items-center text-sm leading-4 text-gray-600">
          <FontAwesome icon="location-dot" /> {{ locationName }}
        </div>
      </div>
    </div>

    <div class="group">
      <hr class="hidden my-3 border-gray-200 group-has-[.pill]:block">
      <CandidacyPills :candidacy="candidacy" />
    </div>

    <hr class="my-3 border-gray-200">

    <div class="flex gap-4 items-center text-sm">
      <div v-if="primaryProfile !== undefined" class="flex gap-2 items-center">
        <ProfileLink :profile="primaryProfile" />

        <Popover v-if="otherProfiles.length > 0">
          <template #trigger>
            <span class="py-1 px-2 text-xs rounded-full bg-primary-100 text-primary-700">
              +{{ otherProfiles.length }}
            </span>
          </template>

          <template #popover>
            <div class="flex flex-col gap-1 p-3">
              <span v-for="profile in otherProfiles" :key="profile.url">
                <ProfileLink :profile="profile" />
              </span>
            </div>
          </template>
        </Popover>
      </div>

      <i class="text-gray-200 fa-thin fa-pipe" />

      <div v-if="primaryEmail !== undefined" class="flex gap-2 items-center">
        <FontAwesome icon="envelope" />
        {{ primaryEmail.address }}

        <Popover v-if="otherEmails.length > 0">
          <template #trigger>
            <span class="py-1 px-2 text-xs rounded-full bg-primary-100 text-primary-700">
              +{{ otherEmails.length }}
            </span>
          </template>

          <template #popover>
            <div class="flex flex-col gap-1 p-3">
              <span v-for="email in otherEmails" :key="email.address">
                {{ email.address }}
              </span>
            </div>
          </template>
        </Popover>
      </div>
    </div>
  </section>
</template>
