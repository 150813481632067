<script setup lang="ts">
import type { Candidacy } from '@grid/types/candidacy'
import { StopCampaigns } from '@grid/mutations/StopCampaigns.gql'
import { useAPI } from '@grid/hooks/useAPI'
import { usePipelineStore } from '@grid/store'

const props = defineProps<{
  text: string
  candidacies: Candidacy[]
}>()

const emit = defineEmits(['completed'])

const { mutate, loading } = useAPI()
const { updateCandidacy } = usePipelineStore()
const candidacyIds = computed(() => props.candidacies.map(c => c.id))

async function stopCampaigns (): Promise<void> {
  mutate({
    mutation: StopCampaigns,
    variables: {
      ids: candidacyIds.value
    }
  }).then(response => {
    response.data.stopCampaigns.candidacies.forEach(({ id, ...rest }) => {
      updateCandidacy(id, rest)
    })
  }).finally(() => emit('completed'))
}
</script>

<template>
  <Action :text="text" :loading="loading" @click="stopCampaigns">
    <template #icon>
      <slot name="icon" />
    </template>
  </Action>
</template>
