import { ApolloClient, InMemoryCache } from '@apollo/client/core'
import typeDefs from './schema.gql'

export default new ApolloClient({
  uri: '/v3/graphql',
  cache: new InMemoryCache({ addTypename: false }),
  typeDefs,
  defaultOptions: {
    query: { fetchPolicy: 'no-cache' }
  }
})
