import { Controller } from '@hotwired/stimulus'

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from '@/v2/pipeline6/App.vue'

export default class extends Controller<HTMLElement> {
  private app: ReturnType<typeof createApp>
  static values = { requisitionId: Number, state: String, editable: Boolean }

  declare requisitionIdValue: number
  declare stateValue: string
  declare editableValue: boolean

  initialize (): void {
    this.app = createApp(App, {
      requisitionId: this.requisitionIdValue,
      state: this.stateValue,
      editable: this.editableValue ?? false
    })

    this.app.use(createPinia())
  }

  connect (): void {
    this.app.mount(this.element)
  }

  disconnect (): void {
    this.app.unmount()
  }
}
