<script setup lang="ts">
import type { ICellRendererParams } from 'ag-grid-community'
import type { Profile } from '../types/profile'
import { useDeletion } from '../hooks/useDeletion'

const props = defineProps<{
  params: ICellRendererParams
}>()

const profiles = ref<Profile[]>(props.params.value)
const { isDeleted, toggleDeletion } = useDeletion<Profile>()

function add (): void {
  profiles.value.push({ url: '' })
}

function save (): void {
  props.params.api.stopEditing()
}

function getValue (): Profile[] {
  return profiles.value.filter(profile => !isDeleted(profile))
}

if (profiles.value.length === 0) {
  add()
}

defineExpose({ getValue })
</script>

<template>
  <div class="p-2 bg-white border border-gray-200 shadow-lg">
    <fieldset class="flex flex-col gap-1 text-sm">
      <div v-for="(profile, index) in profiles" :key="index" class="flex gap-1 items-center">
        <input v-model="profile.url" type="text" class="py-1 px-2 w-full text-sm border border-gray-300 min-w-[200px]">

        <button type="button" @click="toggleDeletion(profile)">
          <i
            class="fa-trash text-primary-700"
            :class="{ 'fa-solid text-red-600': isDeleted(profile), 'fa-regular': !isDeleted(profile) }"
          />
        </button>
      </div>

      <div class="flex gap-1 items-center mt-1">
        <button type="button" class="py-2 w-full text-center btn" @click="add">
          <FontAwesome icon="plus" /> Add
        </button>

        <button type="button" class="w-full text-center btn-primary" @click="save">
          <FontAwesome icon="save" /> Save
        </button>
      </div>
    </fieldset>
  </div>
</template>
