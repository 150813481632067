<script setup lang="ts">
import { usePipelineStore } from './store.ts'

const props = defineProps<{
  text: string
  icon: string
  view: string
  bucket: string
}>()

const { activeView, activeBucket } = storeToRefs(usePipelineStore())

function onClick (): void {
  activeView.value = props.view
  activeBucket.value = props.bucket
}
</script>

<template>
  <a
    class="flex gap-2 items-center py-3 px-4 cursor-pointer"
    :class="{ 'bg-primary-25 border-b-2 border-primary-700 text-primary-700': view === activeView }"
    @click="onClick"
  >
    <i :class="`fa-regular fa-${icon}`" /> {{ text }}
  </a>
</template>
