<script setup lang="ts">
import { usePipelineStore } from './store'
const { activateCandidacy } = usePipelineStore()

defineProps<{
  pager: any
}>()
</script>

<template>
  <div class="flex gap-2 items-center font-semibold text-primary-700">
    <button type="button" class="p-1.5 btn" @click="activateCandidacy(pager.prev.value)">
      <i class="fa-regular fa-fw fa-xl fa-angle-left" />
    </button>

    <button type="button" class="p-1.5 btn" @click="activateCandidacy(pager.next.value)">
      <i class="fa-regular fa-fw fa-xl fa-angle-right" />
    </button>

    <span class="pl-1">
      Candidate {{ pager.currentIndex.value + 1 }} / {{ pager.size.value }}
    </span>
  </div>
</template>
