<script setup lang="ts">
import type { ICellRendererParams } from 'ag-grid-community'
import { usePipelineStore } from '../store'

const { activateCandidacy } = usePipelineStore()

defineProps<{
  params: ICellRendererParams
}>()
</script>

<template>
  <button type="button" @click="activateCandidacy(params.data)">
    <i class="fa-regular fa-user" />
  </button>
</template>
