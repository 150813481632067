<script setup lang="ts">
import type { ICellRendererParams } from 'ag-grid-community'
import type { Email } from '../types/email'
import { useDeletion } from '../hooks/useDeletion'

const props = defineProps<{
  params: ICellRendererParams
}>()

const emails = ref<Email[]>(props.params.value)
const { isDeleted, toggleDeletion } = useDeletion<Email>()

function setAsPrimary (primaryEmail: Email): void {
  emails.value = emails.value.map(email => {
    return { ...email, primary: email === primaryEmail }
  })
}

function add (): void {
  emails.value.push({ address: '', primary: false })
}

function save (): void {
  props.params.api.stopEditing()
}

function getValue (): Email[] {
  return emails.value.filter(email => !isDeleted(email) && email.address.length > 0)
}

if (emails.value.length === 0) {
  add()
}

defineExpose({ getValue })
</script>

<template>
  <div class="p-2 bg-white border border-gray-200 shadow-sm">
    <fieldset class="flex flex-col gap-1 text-sm">
      <div v-for="(email, index) in emails" :key="index" class="flex gap-1 items-center">
        <input v-model="email.address" type="email" class="py-1 px-2 w-full text-sm border border-gray-300 min-w-[200px]">

        <div>
          <i v-if="email.primary" class="fa-solid fa-star text-primary-700" />

          <button v-else type="button" @click="setAsPrimary(email)">
            <i class="fa-regular fa-star text-primary-700" />
          </button>
        </div>

        <button type="button" @click="toggleDeletion(email)">
          <i
            class="fa-trash text-primary-700"
            :class="{ 'fa-solid text-red-600': isDeleted(email), 'fa-regular': !isDeleted(email) }"
          />
        </button>
      </div>

      <div class="flex gap-1 items-center mt-1">
        <button type="button" class="py-2 w-full text-center btn" @click="add">
          <FontAwesome icon="plus" /> Add
        </button>

        <button type="button" class="w-full text-center btn-primary" @click="save">
          <FontAwesome icon="save" /> Save
        </button>
      </div>
    </fieldset>
  </div>
</template>
