<script setup lang="ts">
import { useEventListener } from '@vueuse/core'
import { usePipelineStore } from './store'
import useHootieStore from '@grid/stores/hootie'

const props = defineProps<{
  requisitionId: number
  state: string
  editable: boolean
}>()

const store = usePipelineStore()
const { activeView, editable } = storeToRefs(store)
const { fetchGrid, withLoading } = store

const hootieStore = useHootieStore(props.requisitionId)
const { fetchHootie } = hootieStore

const gridStates = JSON.parse(props.state)
editable.value = props.editable

async function loadGrid (): Promise<void> {
  return Promise.all([
    fetchGrid(props.requisitionId),
    fetchHootie()
  ])
}

useEventListener('turbo:morph', async () => {
  loadGrid()
})

onMounted(async () => {
  withLoading(async () => await loadGrid())
})
</script>

<template>
  <div class="flex flex-col h-full">
    <div class="flex sticky z-10 px-4 font-semibold text-gray-700 bg-white border-b border-gray-200 top-[var(--header-height)] min-h-[var(--header-height)]">
      <ViewTab text="Candidates" icon="user-circle" view="index" bucket="published" />
      <ViewTab text="Inbox" icon="rocket" view="inbox" bucket="replied" />
      <ViewTab text="Done" icon="check" view="done" bucket="interested" />
    </div>

    <IndexGrid
      v-show="activeView === 'index'"
      :requisition-id="requisitionId"
      :state="gridStates['index'] ?? {}"
    />

    <InboxGrid
      v-show="activeView === 'inbox'"
      :state="gridStates['inbox'] ?? {}"
    />

    <DoneGrid
      v-show="activeView === 'done'"
      :state="gridStates['done'] ?? {}"
    />
  </div>

  <CandidateProfile />
</template>

<style>
:root {
  --ag-accent-color: #48a0a7;
  --ag-wrapper-border-radius: 0;
  --ag-font-family: Inter var, ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

& :where(.ag-column-select-header-filter-wrapper, .ag-filter-toolpanel-search, .ag-mini-filter, .ag-filter-filter) {
  .ag-number-field-input, .ag-text-field-input {
    padding-left: calc(var(--ag-spacing) * 1.5 + 12px);
  }
}
</style>
