<script setup lang="ts">
import { useModal } from '@grid/hooks/useModal'

const props = defineProps<{
  icon: string
  text: string
  dialog?: HTMLDialogElement
}>()

const dialog = toRef(props, 'dialog')
const { closeModal } = useModal(dialog)
</script>

<template>
  <header class="flex sticky top-0 z-10 justify-between items-center p-4 bg-gray-50 border-b border-gray-200">
    <h1 class="flex gap-3 items-center text-xl font-semibold">
      <FontAwesome :icon="icon" />
      {{ text }}
    </h1>

    <button type="button" class="p-1.5 btn" @click="closeModal">
      <FontAwesome icon="xmark" size="xl" />
    </button>
  </header>
</template>
