<script setup lang="ts">
import { useFloating, offset, autoUpdate } from '@floating-ui/vue'
import type { Placement } from '@floating-ui/dom'

const trigger = useTemplateRef('trigger')
const popover = useTemplateRef('popover')

interface Props {
  placement?: Placement
  disabled?: boolean
}

const id = crypto.randomUUID()
const props = withDefaults(defineProps<Props>(), { placement: 'bottom-end', disabled: false })
const disabled = toRef(props, 'disabled')

const { x, y } = useFloating(trigger, popover, {
  placement: props.placement,
  middleware: [offset(4)],
  whileElementsMounted: autoUpdate
})

const popoverStyles = computed(() => ({ top: `${y.value}px`, left: `${x.value}px` }))

watch(disabled, newValue => {
  if (newValue) {
    popover.value?.hidePopover()
  }
})
</script>

<template>
  <div class="relative group">
    <button ref="trigger" type="button" :popovertarget="id" :disabled="disabled">
      <slot name="trigger" />
    </button>

    <div
      :id="id"
      ref="popover"
      popover
      class="absolute top-0 left-0 p-0 m-0 w-max text-sm bg-white rounded-md border border-gray-200 shadow-lg min-w-[64px]"
      :style="popoverStyles"
    >
      <slot name="popover" />
    </div>
  </div>
</template>
