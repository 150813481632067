import { defineStore } from 'pinia'
import api from '../api'
import { SaveGridState } from '@grid/mutations/SaveGridState.gql'
import type { GridApi, GridState, Column, GridReadyEvent, SelectionChangedEvent, ModelUpdatedEvent, IRowNode } from 'ag-grid-community'

export default (namespace: string): ReturnType<typeof defineStore> => {
  return defineStore(namespace, () => {
    const gridApi = ref<GridApi>()
    const selections = ref<any[]>([])
    const visibleNodes = ref<IRowNode[]>([])

    const columnDefs = computed(() => {
      return gridApi.value?.getColumnDefs() ?? []
    })

    const columns = computed<Column[]>(() => columnDefs.value
      .map(def => gridApi.value?.getColumn(def.colId))
      .filter(column => column !== null && column !== undefined)
    )

    function gridReady (event: GridReadyEvent): void {
      gridApi.value = event.api
    }

    function resetSelections (): void {
      selections.value = []
      gridApi.value?.deselectAll()
    }

    function selectionChanged (event: SelectionChangedEvent): void {
      selections.value = event.api.getSelectedRows()
    }

    function modelUpdated (event: ModelUpdatedEvent): void {
      const nodes: IRowNode[] = []
      event.api.forEachNodeAfterFilterAndSort(node => nodes.push(node.data))
      visibleNodes.value = nodes
    }

    async function saveState (state: GridState): Promise<void> {
      const cleanState: GridState = { ...state, rowSelection: [], focusedCell: null }

      await api.mutate({
        mutation: SaveGridState,
        variables: { state: JSON.stringify(cleanState), key: namespace }
      })
    }

    return {
      gridApi,
      gridReady,
      columns,
      selections,
      resetSelections,
      selectionChanged,
      visibleNodes,
      modelUpdated,
      saveState
    }
  })()
}
