<script setup lang="ts">
import type { Candidacy } from '@grid/types/candidacy'

const { candidacy } = defineProps<{
  candidacy: Candidacy
}>()

const bounced = computed(() => candidacy.buckets.includes('bounced'))
const rejected = computed(() => candidacy.buckets.includes('rejected'))
const doNotContact = computed(() => candidacy.doNotContact)
</script>

<template>
  <div class="flex gap-3 items-center [&:not(:has(.pill))]:hidden">
    <ErrorPill v-if="bounced" text="Bounced" icon="arrow-trend-down" />
    <ErrorPill v-if="rejected" text="Rejected" icon="user-xmark" />
    <WarningPill v-if="doNotContact" text="Do not contact" icon="hand" />
  </div>
</template>
