<script setup lang="ts">
import useHootieStore from '@grid/stores/hootie'
import hootieAvatar from '@hootie/avatar.png'
import type { ComputedRef } from 'vue'

const props = defineProps<{
  requisitionId: number
}>()

const hootieStore = useHootieStore(props.requisitionId)
const { activeSearch } = storeToRefs(hootieStore)
const { startSearch, stopSearch } = hootieStore

const activeSearchStatus = computed(() => activeSearch.value?.status)

const isWorking: ComputedRef<boolean> = computed(() =>
  ['initialized', 'working'].includes(activeSearchStatus.value)
)
</script>

<template>
  <Popover>
    <template #trigger>
      <div class="flex gap-2 items-center px-3 ml-2 h-9 btn min-w-9">
        <div v-show="isWorking" class="flex gap-2 items-center" >
          <FontAwesome :style="'solid'" icon="spinner-third" :spin="true" />
          <span v-if="activeSearchStatus === 'initialized'">Initializing search...</span>
          <span v-if="activeSearchStatus === 'working'">Searching...</span>
        </div>

        <img v-show="!isWorking" :src="hootieAvatar" class="size-5">
      </div>
    </template>

    <template #popover>
      <div class="h-[var(--hootie-height)] w-[var(--hootie-width)] bg-white overflow-y-auto flex flex-col-reverse ![overflow-anchor:auto] py-3">
        <div class="flex flex-col gap-2 p-2">
          <div v-if="activeSearch === null" class="flex flex-col gap-2">
            <HootieMessage text="Looks like you don't have an active search. What would you like to do?" />
            <HootieAction @click="startSearch">
              👀 Start search
            </HootieAction>
          </div>

          <div v-else class="flex flex-col gap-2">
            <HootieLogs :search="activeSearch" />

            <HootieAction @click="() => { stopSearch(activeSearch) }">
              ✋ Stop search
            </HootieAction>
          </div>
        </div>
      </div>
    </template>
  </Popover>
</template>
