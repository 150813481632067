import type { Bucket } from '../types/bucket'
import { Ref, ComputedRef } from 'vue'

interface Bucketable {
  buckets: string[]
}

interface Returns<T> {
  activeBucket: Ref<Bucket>
  bucketed: ComputedRef<T[]>
  count: ComputedRef<number>
}

export function useBucket<T extends Bucketable> (bucketables: Ref<T[]>, bucket: Bucket): Returns<T> {
  const activeBucket = ref<Bucket>(bucket)

  const bucketed = computed(() => {
    return bucketables.value.filter(bucketable => bucketable.buckets.includes(activeBucket.value))
  })

  const count = computed(() => bucketed.value.length)

  return {
    activeBucket,
    bucketed,
    count
  }
}
