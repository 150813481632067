<script setup lang="ts">
defineProps<{
  label: string
}>()

const model = defineModel()
const id = crypto.randomUUID()
</script>

<template>
  <label :for="id">
    <input :id="id" v-model="model" type="checkbox" class="hidden peer">

    <div class="p-2 text-gray-700 rounded-xl border border-gray-200 cursor-pointer group peer-checked:bg-primary-50 peer-checked:border-2 peer-checked:border-primary">
      <div class="flex gap-4 items-center p-2 peer-checked:group-[]:text-primary-800">
        <div class="text-center w-[24px]">
          <slot name="icon" />
        </div>

        <span class="font-medium">
          {{ label }}
        </span>

        <div class="ml-auto text-gray-300 peer-checked:group-[]:hidden">
          <FontAwesome icon="fa-circle" />
        </div>

        <div class="hidden ml-auto peer-checked:group-[]:block text-primary-700">
          <FontAwesome icon="fa-circle-check" />
        </div>
      </div>
    </div>
  </label>
</template>
