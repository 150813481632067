<script setup lang="ts">
import { useTemplateRef } from 'vue'
import type { Candidacy } from '@grid/types/candidacy'
import { ExportCandidacies } from '@grid/mutations/ExportCandidacies.gql'
import { useAPI } from '@grid/hooks/useAPI'
import { useModal } from '@grid/hooks/useModal'
import excelIconURL from '@grid/assets/excel.svg'
import greenhouseIconURL from '@grid/assets/greenhouse.svg'

const props = defineProps<{
  text: string
  candidacies: Candidacy[]
}>()

const emit = defineEmits(['completed'])

const { mutate, loading } = useAPI()
const candidacyIds = computed(() => props.candidacies.map(c => c.id))

const dialog = useTemplateRef<HTMLDialogElement>('dialog')
const { openModal, closeModal, modalTransition } = useModal(dialog)

const deliveryEmail = ref()
const excel = ref(false)
const csv = ref(false)
const greenhouse = ref(false)

const formats = computed(() => {
  return [
    excel.value && 'excel',
    csv.value && 'csv',
    greenhouse.value && 'greenhouse'
  ].filter(Boolean)
})

async function exportCandidacies (): Promise<void> {
  mutate({
    mutation: ExportCandidacies,
    variables: {
      ids: candidacyIds.value,
      formats: formats.value
    }
  }).then(response => {
    deliveryEmail.value = response.data.exportCandidacies.email
  })
}

function reset (): void {
  excel.value = false
  csv.value = false
  greenhouse.value = false
  deliveryEmail.value = undefined
}

async function onClose (): Promise<void> {
  modalTransition().then(() => reset())

  if (deliveryEmail.value !== undefined) {
    emit('completed')
  }
}
</script>

<template>
  <Action :text="text" :loading="loading" @click="openModal">
    <template #icon>
      <slot name="icon" />
    </template>
  </Action>

  <dialog
    ref="dialog"
    class="overflow-hidden text-left rounded-lg w-[600px] has-[[data-fullscreen]]:min-w-[90vw] max-h-[90vh]"
    @close="onClose"
  >
    <div class="flex overflow-y-hidden flex-col max-h-[90vh]">
      <ModalHeader icon="download" text="Candidate export" :dialog="dialog" />

      <ModalContent>
        <div v-if="deliveryEmail === undefined">
          <p class="mb-4 font-semibold text-gray-700">
            Export for...
          </p>

          <div class="grid grid-cols-2 gap-4 mb-72">
            <ExportFormatCheckbox v-model="excel" label="Excel">
              <template #icon>
                <img :src="excelIconURL">
              </template>
            </ExportFormatCheckbox>

            <ExportFormatCheckbox v-model="csv" label="CSV">
              <template #icon>
                <FontAwesome icon="table-layout" size="2xl" />
              </template>
            </ExportFormatCheckbox>

            <ExportFormatCheckbox v-model="greenhouse" label="Greenhouse">
              <template #icon>
                <img :src="greenhouseIconURL">
              </template>
            </ExportFormatCheckbox>
          </div>
        </div>

        <div v-else>
          <p class="text-center flash notice">
            We're working on your export.
            <br>
            We'll deliver it to <strong class="font-bold">{{ deliveryEmail }}</strong> when it's ready.
          </p>
        </div>
      </ModalContent>

      <ModalFooter>
        <button
          v-if="deliveryEmail === undefined"
          type="button"
          class="flex gap-2 items-center font-medium btn btn-primary"
          :aria-busy="loading"
          :disabled="loading"
          @click="exportCandidacies"
        >
          Export <FontAwesome icon="arrow-right" />
        </button>

        <button v-else type="button" class="font-medium btn btn-primary" @click="closeModal">
          OK
        </button>
      </ModalFooter>
    </div>
  </dialog>
</template>
