<script setup lang="ts">
import type { GridState } from 'ag-grid-community'
import type { Candidacy } from './types/candidacy'
import { usePipelineStore } from './store'
import { useBucket } from './hooks/useBucket'

defineProps<{
  state: GridState
}>()

const bucketDefs = [
  { bucket: 'contacted', text: 'Contacted' },
  { bucket: 'replied', text: 'Replied' },
  { bucket: 'bounced', text: 'Bounced' },
  { bucket: 'unresponsive', text: 'Unresponsive' }
]

const { candidacyDB } = storeToRefs(usePipelineStore())
const { activeBucket, bucketed: candidacies } = useBucket<Candidacy>(candidacyDB, 'replied')
</script>

<template>
  <CandidateGrid
    :state="state"
    :candidacies="candidacies"
    :bucket="activeBucket"
    namespace="inbox"
  >
    <template #buckets>
      <BucketTab
        v-for="bucketDef in bucketDefs"
        :key="bucketDef.bucket"
        :text="bucketDef.text"
        :name="bucketDef.bucket"
        :active="activeBucket === bucketDef.bucket"
        @click="activeBucket = bucketDef.bucket"
      />
    </template>
  </CandidateGrid>
</template>
