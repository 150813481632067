import { defineStore } from 'pinia'
import api from '../api'
import { StartSearch } from '@grid/mutations/hootie/StartSearch.gql'
import { StopSearch } from '@grid/mutations/hootie/StopSearch.gql'
import { GetHootie } from '@grid/queries/hootie/GetHootie.gql'
import type { Search } from '@hootie/types'

export default (requisitionId: number): ReturnType<typeof defineStore> => {
  const storeName = requisitionId.toString()

  return defineStore(storeName, () => {
    const activeSearch = ref<Search | null>(null)

    async function fetchHootie (): Promise<void> {
      const { data } = await api.query({ query: GetHootie, variables: { requisitionId } })
      activeSearch.value = data.activeSearch
    }

    async function startSearch (): Promise<void> {
      const { data } = await api.mutate({
        mutation: StartSearch,
        variables: { requisitionId }
      })

      activeSearch.value = data.startSearch
    }

    async function stopSearch (search: Search): Promise<void> {
      activeSearch.value = null

      try {
        await api.mutate({
          mutation: StopSearch,
          variables: { id: search.id }
        })
      } catch {
        activeSearch.value = search
      }
    }

    return {
      activeSearch,
      startSearch,
      stopSearch,
      fetchHootie
    }
  })()
}
