import apolloClient from '@grid/api'
import type { MutationResult } from '@apollo/client'

interface Returns {
  loading: Ref<boolean>
  mutate: (...args: any[]) => Promise<MutationResult<any>>
}

export function useAPI (): Returns {
  const loading = ref<boolean>(false)

  async function mutate (...args): Promise<MutationResult<any>> {
    loading.value = true

    return apolloClient.mutate(...args)
      .catch((error: any) => console.debug(error))
      .finally(() => {
        loading.value = false
      })
  }

  return {
    mutate,
    loading
  }
}
