<script setup lang="ts">
import { usePipelineStore } from './store'
import { useBucket } from './hooks/useBucket'

const store = usePipelineStore()
const { candidacyDB } = storeToRefs(store)

const props = defineProps<{
  text: string
  name: string
  active?: boolean
}>()

defineEmits(['click'])

const { count } = useBucket(candidacyDB, props.name)
</script>

<template>
  <button
    type="button"
    class="flex gap-1.5 items-center py-1 px-3 bg-gray-100 rounded-full border border-gray-200 active:bg-primary-100 active:border-primary-200"
    :class="{ 'bg-primary-100 border-primary-200': active }"
    @click="$emit('click', name)"
  >
    <span class="font-semibold text-primary-700">
      {{ text }}
    </span>

    <span class="inline-block py-0.5 px-2 text-xs font-medium text-gray-700 bg-gray-100 rounded-full">
      {{ count }}
    </span>
  </button>
</template>
