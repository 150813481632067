<script setup lang="ts">
import { useTemplateRef } from 'vue'
import type { Candidacy } from '@grid/types/candidacy'
import { RejectCandidacies } from '@grid/mutations/RejectCandidacies.gql'
import { useAPI } from '@grid/hooks/useAPI'
import { useModal } from '@grid/hooks/useModal'
import { usePipelineStore } from '@grid/store'

const props = defineProps<{
  candidacies: Candidacy[]
}>()

const emit = defineEmits(['completed'])

const { mutate, loading } = useAPI()
const { updateCandidacy } = usePipelineStore()
const candidacyIds = computed(() => props.candidacies.map(c => c.id))

const dialog = useTemplateRef<HTMLDialogElement>('dialog')
const { closeModal, modalTransition } = useModal(dialog)

const location = ref(false)
const company = ref(false)
const senior = ref(false)
const junior = ref(false)
const title = ref(false)
const skills = ref(false)
const comments = ref('')

function reset (): void {
  location.value = false
  company.value = false
  senior.value = false
  junior.value = false
  title.value = false
  skills.value = false
  comments.value = ''
}

async function reject (): Promise<void> {
  mutate({
    mutation: RejectCandidacies,
    variables: {
      ids: candidacyIds.value,
      location: location.value,
      company: company.value,
      senior: senior.value,
      junior: junior.value,
      title: title.value,
      skills: skills.value,
      comments: comments.value
    }
  }).then(response => {
    response.data.rejectCandidacies.candidacies.forEach(({ id, buckets }) => {
      updateCandidacy(id, { buckets })
    })
  }).finally(() => {
    closeModal()
    emit('completed')
  })
}

async function onClose (): Promise<void> {
  modalTransition().then(() => reset())
}
</script>

<template>
  <button type="button" class="contents" @click="dialog?.showModal()">
    <slot name="trigger" />
  </button>

  <dialog
    ref="dialog"
    class="overflow-hidden text-left rounded-lg w-[600px] has-[[data-fullscreen]]:min-w-[90vw] max-h-[90vh]"
    @close="onClose"
  >
    <div class="flex overflow-y-hidden flex-col max-h-[90vh]">
      <ModalHeader icon="user-xmark" text="Reason for rejection" :dialog="dialog" />

      <ModalContent>
        <div class="grid grid-cols-2 gap-2 my-2">
          <RejectionCheckbox v-model="location" label="Wrong location" />
          <RejectionCheckbox v-model="company" label="Company/industry mismatch" />
          <RejectionCheckbox v-model="senior" label="Too senior" />
          <RejectionCheckbox v-model="junior" label="Too junior" />
          <RejectionCheckbox v-model="title" label="Title mismatch" />
          <RejectionCheckbox v-model="skills" label="Skills mismatch" />
        </div>

        <div class="mt-4">
          <textarea
            v-model="comments"
            placeholder="Comments (optional)"
            class="w-full placeholder-gray-300 text-black rounded-lg border border-gray-300 focus:ring-1 focus:outline-none drop-shadow-sm focus:border-primary focus:ring-primary"
          />
        </div>
      </ModalContent>

      <ModalFooter>
        <button
          type="button"
          class="flex gap-2 items-center font-medium btn btn-primary"
          :aria-busy="loading"
          :disabled="loading"
          @click="reject"
        >
          Submit <FontAwesome icon="arrow-right" />
        </button>
      </ModalFooter>
    </div>
  </dialog>
</template>
