<script setup lang="ts">
import type { ICellRendererParams } from 'ag-grid-community'
import type { Profile } from '../types/profile'

const props = defineProps<{
  params: ICellRendererParams
}>()

const profiles = props.params.value as Profile[] ?? []
</script>

<template>
  <div class="flex gap-1 items-center text-left">
    <a v-for="profile in profiles" :key="profile.url" :href="`https://${profile.url}`" target="_blank" rel="noopener">
      <BrandIcon :brand="profile.url" />
    </a>
  </div>
</template>
