import { defineStore } from 'pinia'
import { FetchResult } from '@apollo/client/core'

import api from './api'
import type { Candidacy } from './types/candidacy'
import type { Campaign } from './types/campaign'
import type { View } from './types/view'
import { GetPipeline } from './queries/GetPipeline.gql'
import { GetCandidacy } from './queries/GetCandidacy.gql'
import { SaveCandidacy } from './mutations/SaveCandidacy.gql'
import { PublishCandidacies } from './mutations/PublishCandidacies.gql'

export const usePipelineStore = defineStore('pipeline', () => {
  const candidacyDB = ref<Candidacy[]>([])
  const campaigns = ref<Campaign[]>([])
  const activeView = ref<View>('index')
  const activeCandidacy = ref<Candidacy>()
  const loading = ref(true)
  const editable = ref(false)

  async function fetchGrid (requisitionId: number): Promise<void> {
    const { data } = await api.query({ query: GetPipeline, variables: { requisitionId } })
    candidacyDB.value = Array.from(data.candidacies ?? [])
    campaigns.value = Array.from(data.campaigns ?? [])
  }

  async function withLoading<T> (fn: () => Promise<T>): Promise<void> {
    loading.value = true
    try {
      await fn()
    } catch (error) {
      console.error('Failed request', error)
    } finally {
      loading.value = false
    }
  }

  async function reloadCandidacy (id: number): Promise<void> {
    const { data } = await api.query({ query: GetCandidacy, variables: { id } })
    updateCandidacy(id, data.candidacy)
  }

  function updateCandidacy (id: number | string, candidacy: Candidacy): void {
    const index = candidacyDB.value.findIndex(c => c.id === id.toString())
    const candidacyWas = candidacyDB.value[index] ?? {}
    const newCandidacy = { ...candidacyWas, ...candidacy }

    candidacyDB.value.splice(index, 1, newCandidacy)

    if (activeCandidacy.value?.id === id) {
      activateCandidacy(newCandidacy)
    }
  }

  async function saveCandidacy (candidacy: any): Promise<FetchResult<Candidacy>> {
    const { id, firstName, lastName, ranking, emails, phones, profiles, location } = candidacy

    return await api.mutate({
      mutation: SaveCandidacy,
      variables: { id, firstName, lastName, ranking, emails, phones, profiles, location }
    })
  }

  async function publishCandidacies (candidacies: Candidacy[]): Promise<void> {
    const { data } = await api.mutate({
      mutation: PublishCandidacies,
      variables: { ids: candidacies.map(c => c.id) }
    })

    data.publishCandidacies.candidacies.forEach((candidacy: Candidacy) => {
      updateCandidacy(candidacy.id, candidacy)
    })
  }

  function activateCandidacy (candidacy: Candidacy): void {
    activeCandidacy.value = candidacy
  }

  return {
    candidacyDB,
    campaigns,
    activeView,
    activeCandidacy,
    activateCandidacy,
    reloadCandidacy,
    saveCandidacy,
    updateCandidacy,
    publishCandidacies,
    withLoading,
    fetchGrid,
    loading,
    editable
  }
})
