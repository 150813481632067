import { Loader } from '@googlemaps/js-api-loader'
import { Ref } from 'vue'
import { watchDebounced } from '@vueuse/core'

const googleLoader = new Loader({
  apiKey: 'AIzaSyAtXz59ctNwkkgvc__MOnn4AC7662Zt2O8',
  version: 'beta',
  libraries: ['places']
})

const { AutocompleteSuggestion } = await googleLoader.importLibrary('places')

export function useGooglePlaces (query: Ref<string>): { places: Ref<google.maps.places.Place[]> } {
  const places = ref<google.maps.places.Place[]>([])

  async function search (query: string): Promise<void> {
    places.value = []

    const { suggestions } = await AutocompleteSuggestion.fetchAutocompleteSuggestions({
      input: query,
      includedPrimaryTypes: [
        'locality',
        'administrative_area_level_1',
        'administrative_area_level_2'
      ]
    })

    suggestions.forEach(suggestion => {
      const place = suggestion.placePrediction?.toPlace()

      if (place !== undefined) {
        place.fetchFields({ fields: ['id', 'formattedAddress'] })
          .then(({ place }) => places.value.push(place))
          .catch(error => console.error(error))
      }
    })
  }

  watchDebounced(query, newQuery => {
    void search(newQuery)
  }, { debounce: 500, maxWait: 1000 })

  return {
    places
  }
}
