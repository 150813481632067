<script setup lang="ts">
import { watch, useTemplateRef } from 'vue'
import { usePipelineStore } from './store'
import { useCandidacyPager } from '@grid/hooks/useCandidacyPager'

const store = usePipelineStore()
const { activeView, activeCandidacy } = storeToRefs(store)

const dialog = useTemplateRef('dialog')
const pager = useCandidacyPager(activeView.value)
const profileUrl = computed(() => activeCandidacy.value?.profileUrl)

function open (): void {
  dialog.value?.showModal()
}

watch(activeCandidacy, newValue => {
  if (newValue !== undefined) {
    open()
  }
})
</script>

<template>
  <dialog ref="dialog" class="mr-0 h-full max-h-full rounded-l-xl w-[700px] group/vue" @close="activeCandidacy = undefined">
    <div class="h-full max-h-full">
      <header class="flex sticky top-0 z-10 justify-between items-center p-4 bg-gray-50 border-b border-gray-200">
        <h1 v-if="pager.size.value === 1" class="flex gap-2 items-center mr-auto text-xl font-semibold">
          <i class="fa-regular fa-user" /> Details
        </h1>

        <CandidatePager v-else :pager="pager" />

        <button type="button" class="p-1.5 btn" @click="dialog?.close()">
          <i class="fa-regular fa-xmark fa-xl fa-fw" />
        </button>
      </header>

      <div class="p-3 pb-0">
        <CandidacyCard v-if="activeCandidacy !== undefined" :candidacy="activeCandidacy" />
      </div>

      <turbo-frame v-if="(profileUrl?.length ?? 0) > 0" id="right" :src="profileUrl" />
    </div>
  </dialog>
</template>
