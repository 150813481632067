<script setup lang="ts">
import type { Bucket } from '@grid/types/bucket'
import type { Candidacy } from '@grid/types/candidacy'
import { MoveBucket } from '@grid/mutations/MoveBucket.gql'
import { useAPI } from '@grid/hooks/useAPI'
import { usePipelineStore } from '@grid/store'

const props = defineProps<{
  bucket: Bucket
  text: string
  candidacies: Candidacy[]
}>()

const emit = defineEmits(['completed'])

const { mutate, loading } = useAPI()
const { updateCandidacy } = usePipelineStore()

const candidacyIds = computed(() => props.candidacies.map(c => c.id))

async function moveBucket (): Promise<void> {
  mutate({
    mutation: MoveBucket,
    variables: { ids: candidacyIds.value, bucket: props.bucket }
  }).then(response => {
    response.data.moveBucket.candidacies.forEach(({ id, buckets }) => {
      updateCandidacy(id, { buckets })
    })
  }).finally(() => {
    emit('completed')
  })
}
</script>

<template>
  <Action :text="text" :loading="loading" @click="moveBucket">
    <template #icon>
      <slot name="icon" />
    </template>
  </Action>
</template>
