<script setup lang="ts">
import type { Candidacy } from '@grid/types/candidacy'
import { usePipelineStore } from '@grid/store'
import useGridStore from '@grid/stores/grid'

const props = defineProps<{
  candidacies: Candidacy[]
}>()

const candidacies = toRef(props, 'candidacies')
const store = usePipelineStore()
const { activeView, campaigns } = storeToRefs(store)
const { resetSelections } = useGridStore(activeView.value)

const canApproveOrReject = computed(() => {
  return candidacies.value.every(c => c.buckets.includes('review'))
})

const canMarkInterested = computed(() => {
  return candidacies.value.every(c =>
    c.buckets.includes('interested') === false && c.buckets.includes('review') === false
  )
})

const canMarkNotInterested = computed(() => {
  return candidacies.value.every(c =>
    c.buckets.includes('rejected') === false && c.buckets.includes('review') === false
  )
})

const canGoBackToReview = computed(() => {
  return candidacies.value.every(c =>
    c.buckets.includes('review') === false && c.ongoingCampaign === null
  )
})

const canRemoveFromCampaign = computed(() => {
  return candidacies.value.every(c => c.ongoingCampaign !== null)
})

function onCompleted (): void {
  resetSelections()
}
</script>

<template>
  <section>
    <ActionMoveBucket v-if="canApproveOrReject" bucket="approved" text="Approve" :candidacies="candidacies" @completed="onCompleted">
      <template #icon>
        <div class="flex justify-center items-center rounded-full border bg-primary-50 size-6 border-primary-200 text-primary-700">
          <FontAwesome icon="thumbs-up" />
        </div>
      </template>
    </ActionMoveBucket>

    <ActionReject v-if="canApproveOrReject" text="Reject" :candidacies="candidacies" @completed="onCompleted">
      <template #icon>
        <div class="flex justify-center items-center rounded-full border focus:text-white bg-error-50 size-6 border-error-200 text-error-700 focus:bg-error-700 focus:border-error-700">
          <FontAwesome icon="thumbs-down" />
        </div>
      </template>
    </ActionReject>

    <hr class="my-1 w-full h-px bg-gray-200 only:hidden">
  </section>

  <section v-if="campaigns.length > 0">
    <ActionStartCampaigns :candidacies="candidacies" @completed="onCompleted" />
    <hr class="my-1 w-full h-px bg-gray-200">
  </section>

  <section>
    <ActionMoveBucket v-if="canMarkInterested" bucket="interested" text="Mark interested" :candidacies="candidacies" @completed="onCompleted">
      <template #icon>
        <FontAwesome icon="trophy" />
      </template>
    </ActionMoveBucket>

    <ActionMoveBucket v-if="canMarkNotInterested" bucket="not_interested" text="Mark not interested" :candidacies="candidacies" @completed="onCompleted">
      <template #icon>
        <FontAwesome icon="user-xmark" />
      </template>
    </ActionMoveBucket>

    <ActionMoveBucket v-if="canGoBackToReview" bucket="in_review" text="Move back to review" :candidacies="candidacies" @completed="onCompleted">
      <template #icon>
        <FontAwesome icon="user-pen" />
      </template>
    </ActionMoveBucket>
    <hr class="my-1 w-full h-px bg-gray-200 only:hidden">
  </section>

  <section>
    <ActionStopCampaigns v-if="canRemoveFromCampaign" text="Remove from campaign" :candidacies="candidacies" @completed="onCompleted">
      <template #icon>
        <FontAwesome icon="user-minus" />
      </template>
    </ActionStopCampaigns>
    <hr class="my-1 w-full h-px bg-gray-200 only:hidden">
  </section>

  <section>
    <ActionExport text="Export" :candidacies="candidacies" @completed="onCompleted">
      <template #icon>
        <FontAwesome icon="download" />
      </template>
    </ActionExport>
  </section>
</template>
