import { Ref } from 'vue'
import { usePipelineStore } from '@grid/store'
import useGridStore from '@grid/stores/grid'
import type { Candidacy } from '../types/candidacy'

interface Returns {
  currentIndex: Ref<number>
  prev: Ref<Candidacy>
  next: Ref<Candidacy>
  size: Ref<number>
}

export function useCandidacyPager (namespace: string): Returns {
  const { selections, visibleNodes } = storeToRefs(useGridStore(namespace))
  const { activeCandidacy } = storeToRefs(usePipelineStore())

  const inSelectionMode = computed<boolean>(() => {
    return (activeCandidacy.value !== undefined) && selections.value.includes(activeCandidacy.value)
  })

  const candidaciesInPager = computed<Candidacy[]>(() => {
    const nodes = (inSelectionMode.value)
      ? visibleNodes.value.filter(node => selections.value.map(c => c.id).includes(node.id))
      : visibleNodes.value

    return nodes ?? []
  })

  const size = computed(() => candidaciesInPager.value.length ?? 0)

  const currentIndex = computed<number>(() => {
    return candidaciesInPager.value.findIndex(c => c.id === activeCandidacy.value?.id) ?? 0
  })

  const prev = computed(() => {
    const index = currentIndex.value - 1
    return index < 0
      ? candidaciesInPager.value[candidaciesInPager.value.length - 1]
      : candidaciesInPager.value[index]
  })

  const next = computed(() => {
    const index = currentIndex.value + 1
    return index >= candidaciesInPager.value.length
      ? candidaciesInPager.value[0]
      : candidaciesInPager.value[index]
  })

  return {
    currentIndex,
    prev,
    next,
    size
  }
}
